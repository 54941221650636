/**
 * External dependencies
 */
import { render } from '@wordpress/element';

/**
 * Internal dependencies
 */
import BookingContainer from '../../components/event-table/container';
import { decodeAttributesFor } from '../../includes/misc-utils';
import CommonState from '../common-context/common-state';
import ProductsState from '../products-context/products-state';
import ScheduleState from './context/schedule-state';

const bookingsContainersHTML = document.getElementsByClassName( 'wc-bookings-availability-main-events-container' );

for ( const bookingsContainerHTML of bookingsContainersHTML ) {
	const attributes = decodeAttributesFor( 'schedule', bookingsContainerHTML.dataset );

	render(
		<CommonState isFrontend={ true } { ...attributes }>
			<ProductsState isFrontend={ true }>
				<ScheduleState isFrontend={ true } { ...attributes }>
					<BookingContainer { ...attributes } />
				</ScheduleState>
			</ProductsState>
		</CommonState>,
		bookingsContainerHTML
	);
}
