/**
 * External dependencies
 */
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import ScheduleContext from './context';

const ScheduleState = ( props ) => {
	let newProps;

	if ( 'undefined' !== props.isFrontend && true === props.isFrontend ) {
		newProps = props;
	} else {
		newProps = props.attributes;
	}

	/**
	 * dateRangeType.
	 */
	const onTimeRangeUpdate = ( dateRangeType ) => {
		props.setAttributes( { dateRangeType } );
	};

	return (
		<ScheduleContext.Provider value={ {
			dateRangeType: newProps.dateRangeType,
			onTimeRangeUpdate: onTimeRangeUpdate,
			showEmptyDates: newProps.showEmptyDates,
		} }
		>
			{ props.children }
		</ScheduleContext.Provider>
	);
};

ScheduleState.propTypes = {
	children: PropTypes.object,
	attributes: PropTypes.object,
	setAttributes: PropTypes.func,
	isFrontend: PropTypes.bool,
};

export default ScheduleState;
